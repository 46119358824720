import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi.
    </Text>
    <p>{`I'm Tony - I built this site to write articles that help software developers like myself find answers to technical problems, and help those starting in this field find direction.
It's also my `}<a parentName="p" {...{
        "href": "/tags/garden"
      }}>{`digital garden`}</a>{`. A place for ideas to take root, grow, and be tended to as time passes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      